import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VForm,
        {
          ref: "form",
          staticClass: "mt-5",
          on: {
            submit: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.submit()
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("SelectBriefingLanguageComponent", {
                on: {
                  handleChangeLanguage: function (e) {
                    return (_vm.selectedLanguage = e)
                  },
                },
              }),
            ],
            1
          ),
          _c(VTextarea, {
            attrs: { outlined: "", label: _vm.$t("overview"), rows: "3" },
            model: {
              value: _vm.financial.FinancialOverview[_vm.selectedLanguage],
              callback: function ($$v) {
                _vm.$set(
                  _vm.financial.FinancialOverview,
                  _vm.selectedLanguage,
                  $$v
                )
              },
              expression: "financial.FinancialOverview[selectedLanguage]",
            },
          }),
          _c(
            VCardActions,
            { staticClass: "pl-0 dxa_modal_actions" },
            [
              _c(
                VBtn,
                {
                  staticClass: "dxa_modal_btnSuccess",
                  attrs: {
                    color: "primary",
                    "min-width": "100",
                    loading: _vm.loading,
                    type: "submit",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("save")))]
              ),
              _c(
                VBtn,
                {
                  staticClass: "dxa_modal_btnError",
                  attrs: { color: "secondary", "min-width": "100" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("go_back")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "text-center mt-8" },
        [
          _c(
            VRow,
            [
              _c("h4", { staticClass: "h4 mx-auto" }, [
                _vm._v(_vm._s(_vm.$tc("manage_financial_field", 2))),
              ]),
              _c(
                VBtn,
                {
                  staticClass: "mb-2",
                  attrs: { color: "primary", rounded: "", fab: "", right: "" },
                  on: {
                    click: function ($event) {
                      return _vm.add_analysis_dialog()
                    },
                  },
                },
                [_c(VIcon, { attrs: { large: "" } }, [_vm._v("mdi-plus")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(VSimpleTable, [
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v(_vm._s(_vm.$t("title")))]),
            _c("th", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.$t("edit"))),
            ]),
            _c("th", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.$t("remove"))),
            ]),
          ]),
        ]),
        _c(
          "tbody",
          _vm._l(
            _vm.analysis.filter(function (x) {
              return x.Type == 1
            }),
            function (item, index) {
              return _c("tr", { key: index }, [
                _c("td", [
                  _vm.gs.isJson(item.Title)
                    ? _c(
                        "div",
                        _vm._l(
                          Object.keys(JSON.parse(item.Title)),
                          function (k, i) {
                            return _c("p", { key: i, staticClass: "mb-1" }, [
                              _c(
                                "b",
                                { staticStyle: { "font-weight": "bold" } },
                                [_vm._v(_vm._s(k.toUpperCase() + ": "))]
                              ),
                              _vm._v(
                                _vm._s("" + JSON.parse(item.Title)[k]) + " "
                              ),
                            ])
                          }
                        ),
                        0
                      )
                    : _c("span", [_vm._v(_vm._s(item.Title))]),
                ]),
                _c(
                  "td",
                  { staticClass: "text-center" },
                  [
                    _c(
                      VBtn,
                      {
                        staticClass: "px-2 ml-1 secondary",
                        attrs: { "min-width": "0", small: "" },
                        on: {
                          click: function ($event) {
                            return _vm.edit_analysis_dialog(item)
                          },
                        },
                      },
                      [
                        _c(VIcon, { attrs: { small: "" } }, [
                          _vm._v("mdi-pencil"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "td",
                  { staticClass: "text-center" },
                  [
                    _c(
                      VBtn,
                      {
                        staticClass: "px-2 ml-1",
                        attrs: { color: "red", "min-width": "0", small: "" },
                        on: {
                          click: function ($event) {
                            return _vm.open_delete_dialog(item)
                          },
                        },
                      },
                      [
                        _c(VIcon, { attrs: { small: "" } }, [
                          _vm._v("mdi-trash-can"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            }
          ),
          0
        ),
      ]),
      _vm.dialog
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _vm.dialog
                ? _c("ManageAnalysis", {
                    attrs: {
                      CompanyId: _vm.company_prop.CompanyId,
                      BriefingId: _vm.BriefingId,
                      IndexOptions: _vm.index_options,
                      Analysis: _vm.analysis_obj,
                      AnalysisType: 1,
                    },
                    on: {
                      close: function ($event) {
                        _vm.dialog = false
                      },
                      reset: _vm.reset_dialog,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.delete_dialog
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.delete_dialog,
                callback: function ($$v) {
                  _vm.delete_dialog = $$v
                },
                expression: "delete_dialog",
              },
            },
            [
              _c("DeleteConfirmationModal", {
                attrs: { obj: _vm.delete_obj, name: _vm.delete_obj.Title },
                on: {
                  close: function ($event) {
                    _vm.delete_dialog = false
                  },
                  delete: _vm.remove_analysis,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }